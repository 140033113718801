import request from "@/api/request";

export function getAlarmPointsExport() {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: `/export/alarmpoints`,
        method: 'GET',
        responseType: 'blob'
    })
}

export function getOrganizationsExport() {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: `/export/organizations`,
        method: 'GET',
        responseType: 'blob'
    })
}

export function getParticipantGroupsExport() {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: `/export/participant-groups`,
        method: 'GET',
        responseType: 'blob'
    })
}

export function getParticipantsExport() {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: `/export/participants`,
        method: 'GET',
        responseType: 'blob'
    })
}
